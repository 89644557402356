import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { LayoutTypes, LayoutWidth, SideBarTheme, SideBarTypes } from "../constants";
import DefaultLayout from "../layouts/default.layout";
import VerticalLayout from "../layouts/vertical.layout";
import { useAppDispatch, useAppSelector } from "../store";
import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
} from "./index";
import { getUserInfo } from "../reducers/user/user.reducer";
import { getListTask } from "../reducers/home/home.reducer";

interface IRoutesProps { }

const AllRoutes = (props: IRoutesProps) => {
  const loggedIn = useAppSelector(state => state.user.loggedIn)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loggedIn) {
      dispatch(getListTask())
      dispatch(getUserInfo())
    }
  }, [loggedIn])

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props} layout={{
                  layoutType: LayoutTypes.LAYOUT_VERTICAL,
                  layoutWidth: LayoutWidth.LAYOUT_WIDTH_FLUID,
                  leftSideBarTheme: SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT,
                  leftSideBarType: SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT,
                  showRightSidebar: false
                }}>
                  {route.element}
                </DefaultLayout>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {authProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                !loggedIn ? (
                  <Navigate
                    to={{
                      pathname: "/auth/login",
                      // hash:route.path,
                      search: "next=" + route.path,
                    }}
                  />
                ) : (
                  <VerticalLayout {...props}>{route.element}</VerticalLayout>
                )
              }
              key={idx}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default AllRoutes;
