import React from "react";
import ReactDOM from "react-dom/client";

import "./i18n";

import App from "./App";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import setupAxiosInterceptors from "./configs/axios.config";
import { logout } from "./reducers/user/user.reducer";
import getStore from "./store";

const store = getStore()

const actions = bindActionCreators({ logout }, store.dispatch)

setupAxiosInterceptors()

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
